<template>
  <v-container class="form-container rounded elevation-3">
    <v-form
      ref="form"
      @submit.prevent="confirmSave()">
      <v-row>
        <v-col
          cols="6"
          style="font-size: 24px;">
          BC Switch
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <v-btn
            :loading="loading"
            :disabled="!isEdit"
            color="success"
            @click="confirmSave()">
            Save
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="formData"
            :items-per-page="-1"
            hide-default-footer>
            <template #[`item.status`]="{ item }">
              <v-switch
                v-model="item.status"
                true-value="active"
                false-value="inactive"
                class="status-switch">
              </v-switch>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import BCProvider from '@/resources/BCProvider'
import { mapActions } from 'vuex'

const BCService = new BCProvider()

export default {
  data () {
    return {
      loading: false,
      headers: [
        {
          text: 'Status',
          value: 'status',
          align: 'center',
          width: '80px',
          sortable: false
        },
        {
          text: 'Endpoints',
          value: 'endpoint'
        },
        {
          text: 'Description',
          value: 'description'
        }
      ],
      formData: [],
      memoFormData: [],
      textFieldRules: [
        (v) => /^[+-]?([0-9]*[.])?[0-9]+$/.test(v) || 'This field is invalid.'
      ]
    }
  },
  computed: {
    isEdit () {
      return JSON.stringify(this.formData) !== JSON.stringify(this.memoFormData)
    }
  },
  mounted () {
    this.getSwitchs()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setErrorPage: 'Components/setErrorPage',
      setModal: 'Components/setModal'
    }),
    async getSwitchs () {
      try {
        this.loading = true

        const { data } = await BCService.getBCSwitch()

        this.formData = JSON.parse(JSON.stringify(data))
        this.memoFormData = JSON.parse(JSON.stringify(data))
      } catch (error) {
        console.error('getSwitchs', error.message)
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code} : ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async updateBCSwitch () {
      try {
        this.loading = true

        await BCService.updateBCSwitch(this.formData)

        this.setSnackbar({
          value: true,
          message: 'Update switch success.',
          type: 'success'
        })

        this.getSwitchs()
      } catch (error) {
        console.error('updateBCSwitch', error.message)
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code} : ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    confirmSave () {
      this.setModal({
        value: true,
        title: 'UpdateBCSwitch',
        message: 'Do you want to update the BCSwitch?',
        confirmText: 'Confirm',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.updateBCSwitch()
      })
    }
  }
}
</script>

<style scoped>
.form-container {
  background-color: white;
  height: fit-content;
}
</style>

<style>
.status-switch .v-input__slot {
  justify-content: center !important;
}
</style>
